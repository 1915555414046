import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import Img from "gatsby-image"
import { Column, Row } from "../components/grid"
import BrandButton from "../components/brand-button"
import EmbedYoutube from "../components/embed-youtube"
import EmbedAppleMusic from "../components/embed-applemusic"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let featuredImgFluid
  if (frontmatter.featuredImage) {
    featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  }
  return (
    <Layout location={location}>
      <SEO title={`${frontmatter.title} | Releases`} />
      <PageTitle title={frontmatter.title} />
      <p className="text-center mb-5 mt-n4">
        {frontmatter.type} * * * {frontmatter.date}
      </p>
      <Row>
        <Column className="col-12 col-md-6">
          {featuredImgFluid && (
            <Img
              fluid={featuredImgFluid}
              className="rounded shadow mx-auto w-75 h-auto mb-5 wp-post-image"
            />
          )}
        </Column>
        <Column className="col-12 col-md-6">
          <div class="text-center mb-3">
            {frontmatter.musicLinkBandcamp && (
              <BrandButton
                brand="bandcamp"
                href={frontmatter.musicLinkBandcamp}
                size="lg"
                label="Buy it now on Bandcamp"
                outline="false"
              />
            )}
          </div>
          <div className="text-center">
            <p>Or stream it now on:</p>
            {frontmatter.musicLinkApple && (
              <BrandButton brand="apple" href={frontmatter.musicLinkApple} />
            )}
            {frontmatter.musicLinkSpotify && (
              <BrandButton
                brand="spotify"
                href={frontmatter.musicLinkSpotify}
              />
            )}
            {frontmatter.musicLinkYoutube && (
              <BrandButton
                brand="youtubemusic"
                href={frontmatter.musicLinkYoutube}
              />
            )}
            {frontmatter.musicLinkTidal && (
              <BrandButton brand="tidal" href={frontmatter.musicLinkTidal} />
            )}
          </div>
        </Column>
      </Row>

      {html && (
        <Row>
          <Column>
            <div
              className="my-4 my-lg-5"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Column>
        </Row>
      )}

      {frontmatter.musicEmbedApple && (
        <Row>
          <Column>
            <div className="my-4 my-lg-5 text-center">
              <h2>Preview the track</h2>
              <EmbedAppleMusic src={frontmatter.musicEmbedApple} />
            </div>
          </Column>
        </Row>
      )}

      {frontmatter.videoEmbedYoutube && (
        <Row>
          <Column>
            <div className="my-4 my-lg-5 text-center">
              <h2>Watch the music video</h2>
              <EmbedYoutube src={frontmatter.videoEmbedYoutube} />
            </div>
          </Column>
        </Row>
      )}

      <Row>
        <Column>
          <div className="my-4 my-lg-5 text-center">
            <Link to="/releases" className="btn btn-outline-primary">
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back to Releases
            </Link>
          </div>
        </Column>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($fileAbsolutePath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      html
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        slug
        title
        type
        musicEmbedApple
        musicLinkTidal
        musicLinkBandcamp
        musicLinkApple
        musicLinkSpotify
        musicLinkYoutube
        videoEmbedYoutube
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
