import React from "react"

const EmbedAppleMusic = ({ src }) => {
  return (
    <div class="text-center">
      <iframe
        allow="autoplay *; encrypted-media *;"
        frameborder="0"
        height="150"
        style={{
          width: "100%",
          maxWidth: "660px",
          overflow: "hidden",
          background: "transparent",
        }}
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
        src={src}
        title="Music track embedded from Apple Music."
      ></iframe>
    </div>
  )
}

export default EmbedAppleMusic
